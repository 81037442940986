<style>
.TableBorder{
 border: 1px solid black;
}
.Geral {
font-family:Arial;
font-size:8px;
width: 550px;
color:black;
}
.Logo {
width: 80px;
}
.TextBlack {
background:black;
color:white;
}

p.small {
  line-height: 1;
  font-size:8px;
  text-align: justify;
}


</style>

<template>
<form
  id="app"
  v-on:submit.prevent="ConsultarRelatorio()"
  method="post"
>
<div>
<b-card-code v-bind:title="RelName">
    <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Nº PLAQUETA:"
          
        >
          <b-form-input
            id="Plaqueta"
            placeholder=""
            v-model="Plaqueta"
            rows="1"
            required
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="NUMERO:"
          
        >
          <b-form-input
            id="Numero"
            placeholder=""
            v-model="Numero"
            rows="1"
          />
        </b-form-group>
      </b-col>
    </b-row>

   <b-row>
         <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="danger"
      type="button"
      @click="$router.push('../Relatorios')"
    >
      <span>RETORNAR</span>
    </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
    <b-button
      variant="primary"
      type="submit"
    >
      <span>CONSULTAR
      </span>
    </b-button>
      </div>
    </b-col>

    </b-row>
  </b-card-code>

<b-card-code title="" v-show="MostraResultado">
  <b-row>
         <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="success"
      type="button"
      @click="GerarPdf()"
    >
      <span>PDF
        <feather-icon
            :icon="'DownloadCloudIcon'"
            size="15"
          />
      </span>
    </b-button>
      </div>
    </b-col>

    </b-row>
    <b-row>
    <div id="element-to-print" v-show="MostraResultado" v-for="Dados in DadosRelatorio" :key="Dados.Plaqueta">

       <div class="Geral">
           <table class="Geral TableBorder">
             <tr >
               <td rowspan="2" ><center>
                 <b-img
                    fluid
                    :src="image"
                    alt=""
                    class="Logo"
                  />
                 </center></td>
               <td rowspan="2" class="TableBorder" >
                 <center>
                   <b>TERMO DE RESPONSABILIDADE</b>
                   <br>
                   Uso de Bem Móvel
                 </center>
               </td>
               <td rowspan="2" >FL-TI-02<br>Versão: 01<br>Data: 22.02.2017</td>
             </tr>
             <tr>
             </tr>
           </table>
       </div>

      <div class="Geral">
       <br>
         <table class="Geral TableBorder">
           <tr class="TableBorder">
             <td colspan="4" class="TextBlack TableBorder">1. DESCRIÇÃO DO OBJETO</td>
           </tr>
           <tr class="TableBorder">
             <td class="TableBorder">BEM MÓVEL:</td>
             <td class="TableBorder">{{Dados.Plaqueta}}</td>
             <td class="TableBorder">NUMERO CHIP:</td>
             <td class="TableBorder">{{Dados.numero}}</td>
           </tr>
           <tr class="TableBorder">
             <td class="TableBorder">DESCRIÇÃO:</td>
             <td colspan="3" class="TableBorder">{{Dados.Descricao}}</td>
           </tr>
           <tr class="TableBorder">
             <td colspan="4" class="TableBorder">COMPLEMENTO:{{Dados.ProdObs}}</td>
           </tr>
         </table>
      </div>

      <div class="Geral">
         <br>
         <table class="Geral TableBorder">
           <tr class="TableBorder">
             <td colspan="4" class="TextBlack">2. DESCRIÇÃO DO RESPONSÁVEL:</td>
           </tr>
           <tr class="TableBorder">
             <td colspan="4" class="TableBorder">NOME: {{Dados.Responsavel}}</td>
           </tr>
           <tr class="TableBorder">
             <td style="width:50%" class="TableBorder">CPF: {{Dados.ENTCPFCGC}}</td>
             <td style="width:50%" class="TableBorder">RG: {{Dados.ENTRGIE}}</td>
           </tr>
           <tr class="TableBorder">
             <td colspan="4" class="TableBorder">ENDEREÇO: {{Dados.ENDERECO}}</td>
           </tr>
           <tr class="TableBorder">
             <td colspan="4" class="TableBorder">OBS: {{Dados.OBS2}}</td>
           </tr>
         </table>
      </div>

      <div class="Geral">
        <p class="small">
          <br>
          Eu abaixo assinado, declaro ter recebido da VAPZA ALIMENTOS S/A um equipamento com as
         características descritas acima, devidamente habilitado para uso em auxílio às minhas atividades
         profissionais diárias, devendo atender-se as seguintes condições de uso:
          <br><br>
          <b>3 - TERMOS DE USO:</b>
          <br><br>
          Por este instrumento, de posse e uso do aparelho aqui descrito e atribuído valor conforme cadastro
           no patrimônio privado, assumo sobre o mesmo inteira e total responsabilidade, pela posse e uso do
           mesmo, sob compromisso de fazer uso conforme a destinação aqui descrita e com responsabilização
           pessoal pela posse do mesmo.
          <br><br>
          Em caso de perda, furto ou roubo, o EMPREGADO deverá notificar imediatamente a prestadora do
           serviço celular e a EMPRESA, bem como providenciar o boletim de ocorrência para entrega em até 48 horas
           para a EMPRESA. Ainda, nestes casos o valor correspondente ao aparelho perdido ou furtado/roubado
           será debitado integralmente do salário do EMPREGADO ou de suas verbas rescisórias, servindo o presente
           termo como expressa autorização para tanto.
          <br><br>
          Em caso de dano ou extravio, no uso indevido do aparelho, seja por culpa ou por dolo,
           o EMPREGADO deverá reembolsar a EMPRESA pelos danos causados, autorizando o EMPREGADO o
           desconto da importância respectiva da sua remuneração mensal ou de suas verbas rescisórias,
           servindo o presente termo como expressa autorização para tanto.
          <br><br>
          O usuário será responsável por quaisquer incidentes de segurança gerados ativa ou
           passivamente pelo equipamento por ele usado.
          <br><br>
          O usuário é inteiramente responsável pela segurança dos dados no(s)
           equipamento(s) que sejam conectados por ele à rede.
          <br><br>
          O usuário deverá observar o código de ética vigente, utilizando o aparelho somente para o
           desempenho das funções profissionais. A utilização de programas de troca ou download de
            arquivos do tipo P2P (Bit Torrent, E-mule e semelhantes) é expressamente proibida.
         <br><br>
          O usuário será responsável por conservar o equipamento em bom estado de uso e conservação.
         <br><br>
          Em caso de cessação da prestação de serviços, ou por simples liberalidade da empresa,
           o aparelho e seus acessórios deverão ser devolvidos nas mesmas condições em que foram recebidos,
            com exceção da depreciação natural do aparelho.
         <br><br>
          O EMPREGADO está ciente de que o aparelho celular é mero facilitador e que não tem o dever
           de mantê-lo ligado após o seu horário normal, pois não tem o dever de ficar à disposição da
            EMPRESA depois que deixar o trabalho.
         <br><br>
          É proibido o uso do aparelho celular para responder mensagens eletrônicas após o
           horário normal de trabalho.
        </p>

        <p class="small">
          Através da assinatura deste documento, comprometo-me a seguir as normas descritas acima para,
           a partir da data de hoje, utilizar o equipamento descrito.
        </p>
      </div>

      <div class="Geral">
        <br>
        <center>Curitiba-PR, ___________ de _______________________________ de __________.</center>

        <br><br><br>

       <center>
       __________________________________________________________________
       <br>
       ASSINATURA DO RESPONSÁVEL
       </center>

      </div>

      <div class="Geral">
      <br><br>
        <table class="Geral TableBorder">
          <tr class="TableBorder">
            <td style="width:50%;" class="TextBlack TableBorder"><center>Elaborado por:</center></td>
            <td style="width:50%;" class="TextBlack TableBorder"><center>Aprovado por:</center></td>
          </tr>
          <tr class="TableBorder">
            <td style="width:50%;" class="TableBorder"><center>Gabriel Souza – Aprendiz do SGI</center></td>
            <td style="width:50%;" class="TableBorder"><center>Gilmar Machado – Gerente de TI e Sistemas</center></td>
          </tr>
        </table>
      </div>

  </div>
    </b-row>
</b-card-code>
</div>
</form>
</template>

<script>
/* eslint-disable global-require */
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BImg,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BImg,
  },
  data() {
    return {
      RelName: '',
      RelRota: '',
      usuario: '',
      image: require('@/assets/images/logo/LogoVapzaRel.png'),
      MostraResultado: false,
      Plaqueta: '',
      Numero: '',
      DadosRelatorio: [
        { Plaqueta: '' },
        { Descricao: '' },
        { ProdObs: '' },
        { Responsavel: '' },
        { ENTCPFCGC: '' },
        { ENTRGIE: '' },
        { ENDERECO: '' },
        { OBS2: '' },
        { numero: '' },
      ],
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio() {
      Spinner.StarLoad()
      let notification = ''
      this.MostraResultado = false
      const usuario = this.$session.get('usuario')
      const PATRIMONIO = this.Plaqueta
      const NUMERO = this.Numero
      const URLpost = `/Report/ExecutaRelTermoResponsabilidadeBemMovel?CODUSU=${usuario.codusu}&PATRIMONIO=${PATRIMONIO}&NUMERO=${NUMERO}`
      await apiPortalSky.post(URLpost, PATRIMONIO).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.DadosRelatorio = response.data
            this.MostraResultado = true
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    GerarPdf() {
      //UtilVUE.ExportDoc('PDF','RelTermoRespoBemMovel','element-to-print','A4',[20, 20, 20, 20])
      UtilVUE.ExportDoc('PDF','RelTermoRespoBemMovel','element-to-print',null)
      
    },
  },
  mounted() {
    
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.MostraReport()
    }
  },
}
</script>
